.app_widget {
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    border-radius: 15px;
    flex-direction: column;

    #icon {
        font-size: 32px;
    }

    #title {
        font-size: 12px;
        font-weight: lighter;
    }
}