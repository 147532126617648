@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

body {
  padding: 0;
  margin: 0;

  background-color: #222;
  color: #eee;
  font-family: "JetBrains Mono", monospace;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

p.head {
  text-transform: uppercase;
  margin-bottom: 10px;
}

p.small {
  text-align: center;
  padding: 25px;
  font-weight: lighter;
  color: #888;
  font-size: 12px;
}

.loading-indicator {
  background: repeating-linear-gradient(to right, #333 0%, #444 50%, #333 100%);
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes gradient { 
  0%   { background-position: 0 0; }
  100% { background-position: -200% 0; }
}