.tvalarm.active {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: red;
    opacity: 50%;

    animation: alarm_bcg 2000ms infinite;
}

@keyframes alarm_bcg {
    0% { opacity: 0%; }
    50% { opacity: 50%; }
    100% { opacity: 0%; }
}