.home {
    .content {
        padding: 25px 15px;

        .app_list {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        }
    }
}