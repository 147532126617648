.tv_alarm_players {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 350px;

    #title {
        font-size: 20px;
        font-weight: bold;
    }

    .players {
        margin-top: 5px;

        .player {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            border-bottom: 2px solid #222;

            .user {
                display: flex;
                gap: 10px;

                #place {
                    font-weight: bold;

                    &.reacted {
                        color: #4caf50;
                    }
                }
            }

            #score {
                font-weight: lighter;
            }
        }
    }
}