.tv_alarm_player {
    text-align: center;
    padding: 25px 10px;

    .btn {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 64px;
        width: 60%;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 1px 1px 5px black;
        border-radius: 25px;
    }

    .btn:not(.disabled):not(.reacted):active {
        box-shadow: inset 1px 1px 5px black;
    }

    .btn.disabled {
        box-shadow: none;
        border: 1px solid black;
        opacity: 50%;
    }

    .btn.reacted {
        box-shadow: inset 1px 1px 5px black;
        background-color: #4caf50;
    }
}