.controller {
    padding: 15px;
    text-align: center;

    .btn_list {
        position: relative;
        margin: 15px auto;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, minmax(100px, 250px));

        .cursor {
            position: absolute;
            transform: translate(-50%, 100%);
            bottom: -5px;
            transition: 500ms left;

            width: 50px;
            height: 3px;
            background-color: #fff;
        }

        .btn {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            aspect-ratio: 1;
            background-color: #000;
            border-radius: 10px;
    
            #cover {
                width: 100%;
            }
        }
    }

    .bingo_controls {
        text-align: left;

        .view {
            margin-top: 5px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            gap: 25px;

            .count {
                font-size: 16px;
                font-weight: lighter;
                #amount {
                    border-top: 1px solid white;
                }
            }

            .btn {
                font-size: 32px;
            }
        }

        &:not([active]) {
            opacity: 50%;
            pointer-events: none;
        }
    }

    .tv_alarm {
        text-align: left;
        margin-top: 15px;

        .view {
            margin: 10px 0;
            display: flex;
            font-size: 32px;
            justify-content: start;
            align-items: center;
            gap: 15px;

            #toggle {
                font-size: 42px;
            }
        }
    }
}