.plan {
    display: flex;

    .time_teller {
        position: relative;
        width: 50px;

        .teller {
            position: absolute;
            width: 50%;
            height: 2px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #eee;
            transition: 250ms;

            &.hidden {
                display: none;
            }
        }
    }

    .event {
        height: 35px;
        margin-bottom: 15px;

        #title {
            font-weight: bold;
        }

        #time {
            font-weight: lighter;
            font-size: 12px;
            color: #888;
        }

        padding-left: 0px;
        transition: 250ms;

        &:not(.active) {
            color: #aaa;
            padding-left: 10px;
            #title {
                font-weight: normal;
            }
        }
    }
}