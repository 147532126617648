.bingo {
    .played_tracks {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        bottom: 0px;
        overflow-y: hidden;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        }

        .track {
            display: flex;
            height: 35px;
            gap: 15px;
            align-items: center;
            font-size: 18px;
            font-weight: lighter;
            padding: 10px 0;
            border-bottom: 1px solid #333;
            
            #num {
                font-size: 16px;
                font-weight: bold;
            }
    
            #cover {
                height: 100%;
            }
        }
    }

}