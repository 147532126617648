.music_shower {
    max-width: 500px;

    .cover_container {
        aspect-ratio: 1;

        #cover {
            width: 100%;
            height: 100%;
        }
    }

    text-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    
    #title {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 24px;
        margin: 10px 0 2px;
    }

    #artists {
        font-weight: lighter;
        font-size: 14px;
        color: #888;
    }

    .progress_bar {
        position: relative;
        margin: 10px 0;
        background-color: #222;
        width: 100%;
        height: 10px;
        border-radius: 10px;
        overflow: hidden;

        .progress {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: #888;
        }
    }

    &.loading {
        #title {
            height: 32px;
            width: 250px;
        }

        #artists {
            height: 20px;
            width: 100px;
        }
    }
}