.timer {
    text-align: center;
    height: 125px;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 4px black;

    #prefix {
        font-weight: lighter;
    }
}