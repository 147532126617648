.tv {
    *::-webkit-scrollbar {
        display: none;
    }

    .dr {
        position: fixed;
        top: 50%;
        left: 0;
        width: 100%;

        transform: translateY(-50%);
    }

    .dashboard {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #000;
        transition: 1000ms opacity;
        opacity: 100%;

        .timer {
            box-shadow: none;
            font-size: 32px;
            background-color: #000;
        }
    
        .music_shower {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 600px;
            transition: 1000ms;

            &.bingo {
                transform: translate(-100%, 0);
                top: 5%;
                left: 95%;
                width: 200px;
                #title { font-size: 14px; margin-top: 5px; }
                #artists { font-size: 10px; }
                .progress_bar { height: 5px; }
            }
        }

        .bingo {
            position: absolute;
            top: 150px;
            bottom: 50px;
            // overflow-x: hidden;
            // overflow-y: scroll;
            overflow: hidden;
            left: 50%;
            transform: translateX(-50%);
            width: 600px;
            transition: 1000ms;

            &.hidden {
                top: 200%;
            }
        }

        &.hidden {
            opacity: 0%;
        }
    }
}