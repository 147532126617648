.songlist {
    .song {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 10px 10px;
        
        
        border-bottom: #888 solid 1px;
        &:first-of-type { border-top: #888 solid 1px; }

        #cover {
            width: 50px;
            aspect-ratio: 1;
        }

        .details {
            margin-left: 10px;
            overflow: hidden;
            white-space: nowrap;
            
            #title {
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .artists_container {
                position: relative;
                width: 300px;
                height: 16px;
                overflow-x: hidden;

                #artists {
                    position: absolute;
                    z-index: -1;
                    font-size: 12px;
                    color: #888;
                    font-weight: lighter;
                    text-wrap: nowrap;
                    min-width: 300px;
                    animation: artists_animation 8s infinite linear;
                }
            }
        }

        &.loading {
            .details {
                #title { height: 16px; width: 150px; margin-bottom: 5px; }
                #artists { height: 12px; width: 75px; }
            }
        }
    }
}

@keyframes artists_animation {
    0% { transform: translateX(0); }
    10% { transform: translateX(0); }
    45% { transform: translateX(calc(-100% + 300px)); }
    55% { transform: translateX(calc(-100% + 300px)); }
    90% { transform: translateX(0); }
    100% { transform: translateX(0); }
}