.planner {
    padding: 10px;
    h1 {
        text-align: center;
        margin: 25px 0;
    }

    select, input, button {
        background-color: #111;
        outline: none;
        border: none;
        color: white;
        padding: 5px 10px;
        border-radius: 2px;
    }

    // button {
    //     padding: 5px 10px;
    // }

    .event {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}