
.music_bingo {
    padding: 140px 5px 15px;

    .timer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
}
